<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item>
              {{ displayText('partner.partner_area', '合作夥伴專區') }}
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="goBackOrList">{{ displayText('partner.partner_list', "合作夥伴列表") }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{
              isReadOnly ? displayText('partner.view_partner', '查看合作夥伴') : isEditing ?displayText('partner.edit_partner', '編輯合作夥伴') : displayText('partner.add_partner', '新增合作夥伴')
            }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? displayText('partner.view_partner', '查看合作夥伴') : isEditing ? displayText('partner.edit_partner', '編輯合作夥伴') : displayText('partner.add_partner', '新增合作夥伴')
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert v-if="validationErrors" :errors="validationErrors"></validation-error-alert>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="'*' + displayText('partner.name', '名稱')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" :state="v$.partner.name.$error ? false : null"
                    v-model="partner.name" :readonly="isReadOnly"></b-form-input>
                  <b-form-invalid-feedback :state="!v$.partner.name.$error">
                    {{ displayText('partner.is_required', '此欄位為必填') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="'*' + displayText('partner.partner_code', '夥伴代碼')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text"
                    :state="v$.partner.partner_code.$error ? false : null" v-model="partner.partner_code"
                    :readonly="isReadOnly"></b-form-input>
                  <b-form-invalid-feedback :state="!v$.partner.partner_code.$error">
                    {{ displayText('partner.is_required', '此欄位為必填') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.outer_code', '外部代碼')" v-if="hiddenField('outer_code')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="partner.outer_code"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.avatar', '個人頭像')" v-if="hiddenField('avatar')">
                  <li class="s-image-list-item s-draggable" v-if="image || partner.logo_url">
                    <img class="s-cropped-image" :src="image || partner.logo_url">
                    <button v-if="!isReadOnly" class="btn btn-danger s-delete-btn" type="button"
                      @click="deleteImage()">Ｘ</button>
                  </li>
                  <li class="s-image-list-item" v-else>
                    <ImageSelector v-if="!isReadOnly" @input="selectImage">
                      <template #trigger="{ openFileSelector }">
                        <button class="btn s-trigger-btn" @click="openFileSelector">＋ {{ displayText('partner.add_avatar', '新增頭像') }}}</button>
                      </template>
                    </ImageSelector>
                  </li>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.order', '順序')" v-if="hiddenField('order')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="partner.order"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>


                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.phone', '電話')" v-if="hiddenField('telephone')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="partner.telephone"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.fax', '傳真')" v-if="hiddenField('fax')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="partner.fax"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <div class="city-selector-set-has-value" data-has-zipcode>
                  <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                    v-bind:label="displayText('partner.city', '城市')" v-if="hiddenField('city')">
                    <select class="form-control form-control-sm county" v-model="partner.city"
                      :disabled="isReadOnly"></select>
                  </b-form-group>

                  <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.district', '鄉鎮市')" v-if="hiddenField('district')">
                    <select class="form-control form-control-sm district" v-model="partner.district"
                      :disabled="isReadOnly" @change="handleChangeZipcode"></select>
                  </b-form-group>

                  <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                    v-bind:label="displayText('partner.zipcode', '郵遞區號')" v-if="hiddenField('zipcode')">
                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0 zipcode" type="text" v-model="partner.zipcode"
                      v-bind:placeholder="displayText('partner.zipcode', '郵遞區號')" readonly></b-form-input>
                  </b-form-group>
                </div>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.address', '地址')" v-if="hiddenField('address')">
                  <b-form-input class="mb-2 mr-sm-2 mb-sm-0" type="text" v-model="partner.address"
                    :readonly="isReadOnly"></b-form-input>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.is_enabled', '是否啟用')" v-if="hiddenField('is_enabled')">
                  <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="partner.is_enabled"
                    :disabled="isReadOnly"></b-form-checkbox>
                </b-form-group>

                <b-form-group label-cols="12" label-cols-lg="4" label-size="m" content-cols="12" content-cols-lg="6"
                  v-bind:label="displayText('partner.status', '狀態')" v-if="hiddenField('status')">
                  <b-form-select
                    v-model="partner.status"
                    :disabled="isReadOnly"
                    :options="statusOptions"
                    class="mr-3"
                  ></b-form-select>
                </b-form-group>

              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button class="mr-3" @click="goBackOrList" variant="outline-danger">返回
              </b-button>
              <b-button v-if="!isReadOnly" @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import partnerApi from "@/apis/partner";
import { zh } from "vuejs-datepicker/dist/locale"
import TwCitySelector from "tw-city-selector";
import imageApi from "@/apis/image";
import ImageSelector from '@/components/ImageSelector'
import { displayText } from '@/utils/dashboardUtils'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { ImageSelector },
  validations() {
    return {
      partner: {
        org_id: {},
        name: { required },
        partner_code: { required },
        outer_code: {},
        logo_url: {},
        order: {},
        telephone: {},
        fax: {},
        city: {},
        district: {},
        address: {},
        zipcode: {},
        is_enabled: {},
        status: {},
      }
    };
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      twCitySelector: null,
      statusOptions: [
        { text: displayText('partner.status_approved', '通過審核'), value: "approved" },
        { text: displayText('partner.status_pending', '待審核'), value: "pending" },
        { text: displayText('partner.status_failed', '審核失敗'), value: "rejected" },
      ],
      partner: {
        org_id: null,
        name: null,
        partner_code: null,
        outer_code: null,
        logo_url: null,
        order: 0,
        telephone: null,
        fax: null,
        city: null,
        district: null,
        address: null,
        zipcode: null,
        is_enabled: true,
        status: null,
      },
      showLoadingUpload: false,
      image: null,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    ...mapGetters('route', ['queryParams']),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),

    isReadOnly() {
      return this.$route.name === "PartnerView";
    },
  },
  mounted() {
    if (!this.$route.params.partnerId) {
      this.isEditing = false;
    } else {
      this.fetchPartner();
      this.isEditing = true;
    }
    this.twCitySelector = new TwCitySelector({
      el: ".city-selector-set-has-value",
      elCounty: ".county",
      elDistrict: ".district",
      elZipcode: ".zipcode",
    });
  },
  methods: {
    displayText,
    hiddenField(fieldName) {
      if (this.getModuleConfig("partner", "partner.hidden_fields")) {
        return !this.getModuleConfig("partner", "partner.hidden_fields").includes(fieldName);
      } else {
        return true
      }
    },
    async fetchPartner() {
      this.showLoading = true;
      const response = await partnerApi.getPartner(
        this.organization,
        this.$route.params.partnerId
      );
      this.partner = response.data.data;
      this.twCitySelector.setValue(
        this.partner.city || "",
        this.partner.district || ""
      );
      this.showLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.image) {
          const resp = await this.uploadImage();
          if (!(resp.status && resp.status === 200)) {
            return;
          }
        }
        if (this.isEditing) {
          let response = await partnerApi.updatePartner(this.organization, this.partner);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

          }
        } else {
          let response = await partnerApi.createPartner(this.organization, this.partner);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });
          }
        }
        this.goBackOrList()
      } catch (error) {
        console.error(error);
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleChangeZipcode() {
      setTimeout(() => {
        this.partner.zipcode = document.querySelector(".zipcode").value;
      }, 0);
    },
    goBackOrList() {
      this.$router.push({name: 'PartnerList', query: this.queryParams });
    },
    async uploadImage() {
      try {
        let response = await imageApi.uploadByBase64(this.image);
        this.partner.logo_url = response.data.data.image.url
        return response
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    selectImage(image) {
      this.image = image;
    },
    deleteImage() {
      this.image = null;
      this.partner.logo_url = null;
    },
  },
};
</script>
<style scoped>
.btn+.btn {
  margin-left: 0.5rem;
}

.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: 7rem;
  height: 7rem;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
